import './App.css';
import Navbar from './components/Navbar';
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserPage from './components/UserPage';
import CreatorsPage from './pages/CreatorsPage';
import Footer from './components/Footer';
import SellPage from './pages/SellPage.js';
import CalculatorPage from './pages/CalculatorPage';
import ApplyPage from './pages/ApplyPage';
import { useQueryClient, QueryClient, QueryClientProvider } from 'react-query';


const gememoji = "&#128142;"

const usersData = [
  {
    Name: "Fettuccine",
    Handle: "fettuccine_nic",
    Link: "https://twitter.com/fettuccine_nic",
    Image: "https://pbs.twimg.com/profile_images/1677258662417514496/67Js_kbx_400x400.jpg",
    FollowerRange: "20k-25k",
    ExpertisePrice: "$1111",
    ExpertiseLink: "https://buy.stripe.com/6oEcMO2XreZs8aQdQQ",
    InfluencePrice: "$333",
    InfluenceLink: "https://buy.stripe.com/eVa3cedC57x08aQeUV",
    Tags: [
      { name: "Web3", class: "tag-web3" },
      { name: "Tech", class: "tag-tech" },
      { name: "Startups", class: "tag-startups" },
      { name: "Creative", class: "tag-creative" },
      { name: "Entrepreneurship", class: "tag-entrepreneurship" },
      { name: "Money", class: "tag-money" },
    ]
  },
  {
    Name: "m00re",
    Handle: "xxiivmmiv",
    Link: "https://twitter.com/xxiivmmiv",
    Image: "https://pbs.twimg.com/profile_images/1658897645383872517/7Xpw_Oor_400x400.jpg",
    FollowerRange: "1k-3k",
    ExpertisePrice: "$10",
    ExpertiseLink: "https://buy.stripe.com/aEU7to8e7cnn2gU288",
    InfluencePrice: "$5",
    InfluenceLink: "https://buy.stripe.com/dR67to51Vcnn2gU289",
    fairValueListing: true,
    Tags: [
      { name: '💎', class: "tag-gem" },
      { name: "Entrepreneurship", class: "tag-entrepreneurship" },
      { name: "Web3", class: "tag-web3" },
      { name: "Creative", class: "tag-creative" },
    ]
  },
  {
    Name: "bmeche",
    Handle: "brock_meche",
    Link: "https://twitter.com/brock_meche",
    Image: "https://pbs.twimg.com/profile_images/1621535804886482946/msom1YEG_400x400.jpg",
    FollowerRange: "1k-3k",
    ExpertisePrice: "$100",
    ExpertiseLink: "https://buy.stripe.com/5kAcOu8lxbqN1PO000",
    InfluencePrice: "$30",
    InfluenceLink: "https://buy.stripe.com/bIY15M59lbqN0LK7st",
    Tags: [
      { name: "Web3", class: "tag-web3" },
      { name: "Startups", class: "tag-startups" },
      { name: "Creative", class: "tag-creative" },
      { name: "Entrepreneurship", class: "tag-entrepreneurship" },
    ]
  },
  {
    Name: "Twain",
    Handle: "twainmeta",
    Link: "https://twitter.com/twainmeta",
    Image: "https://pbs.twimg.com/profile_images/1654561626291314689/eEK8D19k_400x400.jpg",
    FollowerRange: "10k-15k",
    ExpertisePrice: "$30",
    ExpertiseLink: "https://buy.stripe.com/7sIbKTcpe3Ljc809AA",
    InfluencePrice: "$20",
    InfluenceLink: "https://buy.stripe.com/3cs4ir60QdlT4Fy9AB",
    fairValueListing: true,
    Tags: [
      { name: '💎', class: "tag-gem" },
      { name: "Writing", class: "tag-writing" },
      { name: "Tech", class: "tag-tech" },
      { name: "Web3", class: "tag-web3" },
    ]
  },
  {
    Name: "Clint",
    Handle: "clintsealwood",
    Link: "https://twitter.com/clintsealwood",
    Image: "https://pbs.twimg.com/profile_images/1633548307908767744/HnejqXOI_400x400.jpg",
    FollowerRange: "15k-20k",
    ExpertisePrice: "$1000",
    ExpertiseLink: "https://buy.stripe.com/9AQfZp1s1gaO3LO3ce",
    InfluencePrice: "$300",
    InfluenceLink: "https://buy.stripe.com/4gw3cD5Ih3o2beg5kn",
    Tags: [
      { name: "Web3", class: "tag-web3" },
    ]
  },
  {
    Name: "Diana",
    Handle: "diana_web3",
    Link: "https://twitter.com/diana_web3",
    Image: "https://pbs.twimg.com/profile_images/1657765592756092928/OAPhZJnf_400x400.png",
    FollowerRange: "1k-3k",
    ExpertisePrice: "$50",
    ExpertiseLink: "https://buy.stripe.com/7sI8wx14x7Racnu144",
    InfluencePrice: "$50",
    InfluenceLink: "https://buy.stripe.com/eVafYZ14x0oI0EM5kl",
    Tags: [
      { name: "Web3", class: "tag-web3" },
    ]
  },
  {
    Name: "arijitworks",
    Handle: "arijitworks",
    Link: "https://twitter.com/arijitworks",
    Image: "https://pbs.twimg.com/profile_images/1666848157303488513/czhhAzmK_400x400.jpg",
    FollowerRange: "1k-3k",
    ExpertisePrice: "$50",
    ExpertiseLink: "",
    InfluencePrice: "$5",
    InfluenceLink: "",
    Tags: [
      { name: "Creative", class: "tag-creative" },
    ]
  },
  {
    Name: "JT Crypto",
    Handle: "JTCryptoo",
    Link: "https://twitter.com/jtcryptoo",
    Image: "https://metadata.y00ts.com/y/12843.png",
    FollowerRange: "3k-6k",
    ExpertisePrice: "$50",
    ExpertiseLink: "https://buy.stripe.com/00g2ad0qDa8R7U4cMM",
    InfluencePrice: "$20",
    InfluenceLink: "https://buy.stripe.com/7sIg133CPcgZ4HS3cd",
    Tags: [
      { name: "Entrepreneurship", class: "tag-entrepreneurship" },
    ]
  },
  {
    Name: "Matthew",
    Handle: "themattrogo",
    Link: "https://twitter.com/themattrogo",
    Image: "https://pbs.twimg.com/profile_images/1654275068971700224/Aoq1YiIc_400x400.jpg",
    FollowerRange: "0-999",
    ExpertisePrice: "$100",
    ExpertiseLink: "https://buy.stripe.com/cN202U2enbAA5ZC6op",
    InfluencePrice: "$50",
    InfluenceLink: "https://buy.stripe.com/8wM02U9GP6gg1Jm9AA",
    Tags: [
      { name: "Entrepreneurship", class: "tag-entrepreneurship" },
      { name: "Creative", class: "tag-creative" },
    ]
  },
  {
    Name: "IV",
    Handle: "IVpath",
    Link: "https://twitter.com/ivpath",
    Image: "https://pbs.twimg.com/profile_images/1642931976972673024/n-D89mMB_400x400.jpg",
    FollowerRange: "3k-6k",
    ExpertisePrice: "$57",
    ExpertiseLink: "https://buy.stripe.com/dR68Ah1JGbQXcJqcMN",
    InfluencePrice: "$24",
    InfluenceLink: "https://buy.stripe.com/14keYF1JG7AH5gY000",
    fairValueListing: true,
    Tags: [
      { name: '💎', class: "tag-gem" },
      { name: "Writing", class: "tag-writing" },
      { name: "Tech", class: "tag-tech" },
      { name: "Web3", class: "tag-web3" },
      { name: "Entrepreneurship", class: "tag-entrepreneurship" },
    ]
  },
  {
    Name: "G",
    Handle: "hashmonke",
    Link: "https://twitter.com/hashmonke",
    Image: "https://pbs.twimg.com/profile_images/1592700916146241545/uNvbXU2g_400x400.jpg",
    FollowerRange: "0-999",
    ExpertisePrice: "$12",
    ExpertiseLink: "",
    InfluencePrice: "$5",
    InfluenceLink: "",
    fairValueListing: true,
    Tags: [
      { name: '💎', class: "tag-gem" },
      { name: "Startups", class: "tag-startups" },
      { name: "Web3", class: "tag-web3" },
    ]
  },
];



function App() {
  return (
    <div className="App">
      <Router>
        <Navbar users={usersData} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/set-up" element={<ApplyPage />} />
          <Route path="/sell" element={<SellPage />} />
          <Route path="/price" element={<CalculatorPage />} />
          <Route path="/creators" element={<CreatorsPage users={usersData} />} />
          {usersData.map((user, index) => (
            <Route path={`/${user.Handle}`} key={index} element={<UserPage user={user} />} />
          ))}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;