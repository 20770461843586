import React from 'react';
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer>

    <div className="apply">
        <h2>Want to monetize your Twitter account?</h2>
        <p id="sub-foot">Apply for early creator access today. Average time to submit is 1m.</p>  
        <a href="https://tally.so/r/w8zPlO" target="_blank"><button id="applybtn">Apply for early creator access</button></a>
    </div>
      <div className="footer-content">
        <div className="company-info">
          <h3>&#x1F426; Upbird</h3>
          <div className="email">
      <iframe src="https://embeds.beehiiv.com/d7845063-9236-48a2-995e-466f56c8322e?slim=true" data-test-id="beehiiv-embed" height="52" frameborder="0" scrolling="no"></iframe>
      </div>
        </div>
        <div className="footer-links">
          <h4>Socials</h4>
          <ul>
            <li><a href="https://twitter.com/joinupbird">Twitter</a></li>
            <li><a href="https://discord.gg/U2CuPvG2nq">Discord</a></li>
          </ul>
        </div>
        <div className="footer-links">
          <h4>Contact</h4>
          <ul>
            <li><a href="https://discord.gg/U2CuPvG2nq">Discord</a></li>
            <li><a href="https://discord.gg/U2CuPvG2nq">Founders</a></li>
            <li><a href="https://twitter.com/joinupbird">Twitter</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Upbird. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;