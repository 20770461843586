import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Creators.css';
import '../App.css';
import mixpanel from 'mixpanel-browser';
import thumbicon from '../img/thumbicon.png';
import Tippy from '@tippyjs/react';
import Select from 'react-select'

mixpanel.init('43ddb0bbd6b4ee5d7a9b61987a52f9fd', { debug: true });

const CreatorsPage = ({ users, user }) => {

  const followerRangeMapping = {
    "0-999": "0-999 followers",
    "1k-3k": "1k-3k followers",
    "3k-6k": "3k-6k followers",
    "6k-10k": "6k-10k followers",
    "10k-15k": "10k-15k followers",
    "15k-20k": "15k-20k followers",
    "20k-25k": "20k-25k followers",
    "25k-30k": "25k-30k followers",
    "30k+": "30k+ followers",
  };

  const getFollowerRangeClassName = (range) => {
    if (range === "0-999") {
      return "none-999";
    } else if (range === "1k-3k") {
      return "onek-threek";
    } else if (range === "3k-6k") {
      return "threek-sixk";
    } else if (range === "6k-10k") {
      return "sixk-tenk";
    } else if (range === "10k-15k") {
      return "tenk-fifteenk";
    } else if (range === "15k-20k") {
      return "fifteenk-twentyk";
    } else if (range === "20k-25k") {
      return "twentyk-twentyfivek";
    } else if (range === "25k-30k") {
      return "twentyfivek-thirtyk";
    } else if (range === "30k+") {
      return "thirtyk-plus";
    } else {
      return "";
    }
  };

  const handleExpertiseButtonClick = (event, user) => {
    event.stopPropagation();
    // Logic to open the creator's profile and expertise modal
    // You can redirect the user to the specific creator's profile page and open the modal there.
    // You can use React Router to handle the navigation.
    // Example: history.push(`/${user.Handle}?modal=expertise`);
  };

  const handleInfluenceButtonClick = (event, user) => {
    event.stopPropagation();
    // Logic to open the creator's profile and influence modal
    // You can redirect the user to the specific creator's profile page and open the modal there.
    // You can use React Router to handle the navigation.
    // Example: history.push(`/${user.Handle}?modal=influence`);
  };

  const [hoveredUser, setHoveredUser] = useState(null);

  const handleImageMouseEnter = (user) => {
    setHoveredUser(user);
  };

  const handleImageMouseLeave = () => {
    setHoveredUser(null);
  };

  const handleNameMouseEnter = (user) => {
    setHoveredUser(user);
  };

  const handleNameMouseLeave = () => {
    setHoveredUser(null);
  };

  const [selectedNiche, setSelectedNiche] = useState("");

const handleNicheChange = (selectedOption) => {
  const selectedValues = selectedOption.map((option) => option.value);
  setSelectedNiche(selectedValues);
};

const filteredUsers = selectedNiche.length > 0
  ? users.filter((user) =>
      user.Tags && selectedNiche.every((selectedTag) => user.Tags.some((tag) => tag.name === selectedTag))
    )
  : users;

  const mixpanelcreatoropen = () => {
    mixpanel.track("Creator open");
  };

  const mixpanelapplybtn = () => {
    mixpanel.track("Apply btn click creator page");
  };

  const options = [
    { value: '💎', label: 'Fair value' },
    { value: 'Money', label: 'Money' },
    { value: 'Web3', label: 'Crypto/Web3' },
    { value: 'Tech', label: 'Tech' },
    { value: 'Writing', label: 'Writing' },
    { value: 'Startups', label: 'Startups' },
    { value: 'Entrepreneurship', label: 'Entrepreneurship' },
    { value: 'Creative', label: 'Creative' },
  ]

  return (
    <div className="CreatorsPage">
      <div className="CreatorsPage-top">
        <h1 id="creatorstitle">Creators</h1>
        <a href="https://tally.so/r/w8zPlO" target="_blank" onClick={mixpanelapplybtn}>
          <p id="creators-apply">+ Become a creator</p>
        </a>
        <div className="filter-container">
          <label htmlFor="niche-filter"></label>
          <div className="custom-select">
          <Select
  value={options.filter((option) => selectedNiche.includes(option.value))}
  onChange={handleNicheChange}
  options={options}
  menuPlacement="auto"
  isMulti
/>          </div>
        </div>
      </div>

      <div className="creators-container">
        {filteredUsers.map((user, index) => {
          const FollowerRange = followerRangeMapping[user.FollowerRange];
          const followerRangeClassName = getFollowerRangeClassName(user.FollowerRange);

          const isFairValueListing = user.fairValueListing; // Check the "fairValueListing" property

          return (
            <div
              className={`creator-card ${selectedNiche === user.Niche ? "selected-niche" : ""}`}
              key={index}
              onClick={mixpanelcreatoropen}
            >
              <Link to={`/${user.Handle}`} style={{ textDecoration: 'none' }}>
                <div className="creator-card__image-container">
                  
                  <img
                    className={`creator-card__image ${isFairValueListing ? "fair-value-listing" : ""}`}
                    src={user.Image}
                    alt={user.Name}
                    onMouseEnter={() => handleImageMouseEnter(user)}
                    onMouseLeave={handleImageMouseLeave}
                  />

                  {hoveredUser === user && (
                    <div className="creator-card__buttons">
                      <button
                        className="creator-card__button expertise-button"
                        onClick={(e) => handleExpertiseButtonClick(e, user)}
                        onMouseEnter={() => handleImageMouseEnter(user)}
                        onMouseLeave={handleImageMouseLeave}
                      >
                        Expertise: {user.ExpertisePrice}
                      </button>

                      {/* Influence button */}
                      <button
                        className="creator-card__button influence-button"
                        onClick={(e) => handleInfluenceButtonClick(e, user)}
                        onMouseEnter={() => handleImageMouseEnter(user)}
                        onMouseLeave={handleImageMouseLeave}
                      >
                        Influence: {user.InfluencePrice}
                      </button>
                    </div> )}

                {isFairValueListing && (
                  <Tippy className="tippy-tooltip2" content={`${user.Name} is priced at, or under fair value.`}>
                  <div className="thumbs-up-icon">
                    <p id="thumbicon">💎</p>
                  </div>
                  </Tippy>
                )}
                </div>
                <h2 className="creator-card__name" onMouseEnter={() => handleNameMouseEnter(user)} onMouseLeave={handleNameMouseLeave}>{user.Name}</h2>
              </Link>
              <div className="creator-card__tags">
                {user.Tags &&
                  user.Tags.map((tag, tagIndex) => (
                    <span key={tagIndex} className={`tag ${tag.class}`}>
                      {tag.name}
                    </span>
                  ))}
              </div>
              <span className={`creator-card__handle`}>@{user.Handle}</span>
              <span className={`follower-range ${followerRangeClassName}`}>{FollowerRange}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CreatorsPage;