import React, { useState, useEffect, useRef } from 'react';
import '../styles/Creators.css';
import '../App.css';
import '../App';
import '../index';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import mixpanel from 'mixpanel-browser';
import Tippy from '@tippyjs/react';
import Stripe from '../img/stripe.png';
import Eth from '../img/eth.png';
import axios from 'axios';
import '@rainbow-me/rainbowkit/styles.css';
import { useAccount } from 'wagmi';
import { usePrepareContractWrite, useContractWrite } from 'wagmi';
import contractInterface from '../components/contract-abi.json';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {parseEther} from 'viem';
import searchIcon from '../img/searchicon.png';
import payIcon from '../img/payicon.png';
import messageIcon from '../img/messageicon.png';


mixpanel.init('43ddb0bbd6b4ee5d7a9b61987a52f9fd', {debug: true});

const UserPage = ({ user }) => {

  const { Name, Handle, Link, Image, ExpertisePrice, InfluencePrice, FollowerRange, ExpertiseLink, InfluenceLink, WalletAddress,  } = user;

  const { isConnected } = useAccount();

  const { config } = usePrepareContractWrite({
    addressOrName: '0x0FF76C3353D42ff2588DfAaF580e29988Ffd124d',
    contractInterface: contractInterface,
    functionName: 'sendEther',
    args: [WalletAddress],
    value: parseEther('0.01'),
  });
  
  const { write: sendEther } = useContractWrite(config); 

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);

  const handleFirstModalClick = () => {
    setIsModalOpen(true);
  };

  const handleSecondModalClick = () => {
    setIsModal2Open(true);
  };
  
  const copyDisclaimerText2 = () => {
    const textField = document.createElement('textarea');
    textField.innerText = `Hey ${Name}, I saw your Influence deal on Upbird. Care to chat?`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    

    setIsCopyPopupVisible(true);

    // Hide the popup after a few seconds
    setTimeout(() => {
      setIsCopyPopupVisible(false);
    }, 2000);
  };

  const copyDisclaimerText = () => {
    const textField = document.createElement('textarea');
    textField.innerText = `Hey ${Name}, I saw your Expertise deal on Upbird. Care to chat?`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    setIsCopyPopupVisible(true);

    // Hide the popup after a few seconds
    setTimeout(() => {
      setIsCopyPopupVisible(false);
    }, 2000);
  };

  const [isCopyPopupVisible, setIsCopyPopupVisible] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains('modal')) {
        closeModal();
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setIsModal2Open(false);
  };

  const getFollowerRangeClassName = (range) => {
    if (range === "0-999") {
      return "none-999";
    } else if (range === "1k-3k") {
      return "onek-threek";
    } else if (range === "3k-6k") {
      return "threek-sixk";
    } else if (range === "6k-10k") {
      return "sixk-tenk";
    } else if (range === "10k-15k") {
      return "tenk-fifteenk";
    } else if (range === "15k-20k") {
      return "fifteenk-twentyk";
    } else if (range === "20k-25k") {
      return "twentyk-twentyfivek";
    } else if (range === "25k-30k") {
      return "twentyfivek-thirtyk";
    } else if (range === "30k+") {
      return "thirtyk-plus";
    } else {
      return "";
    }
  };

  
  const ModalRectangle = ({ onClose, title, content, price }) => {
    
    const fee = parseFloat(price.replace('$', '')) * 0.05;
    const total = parseFloat(price.replace('$', ''));

    const mixpanelexpertisemsg = () => {
      mixpanel.track("Expertise Message Click");
    };

    
    return (


        

      <div className="modal">
        <div className="modal-conten">
        <a onClick={onClose}><h4 id="back"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg> Go back</h4></a>
          <h3 id="modal-title">{title}</h3>
          <p id="modal-subtitle">{Name} (aka @{Handle}) will work with you to create a requested piece of content for their <span className={`follower-range ${followerRangeClassName}`}>{FollowerRange} followers</span></p>
          <div>
            <h4 id="sub-step"><span id="step">Step 1. </span>Message {Name}. Use the example message below or type your own.</h4>
            <div className="copy-section">
            <div className="copy-text">Hey {Name}, I saw your Expertise deal on Upbird. Care to chat?</div>
            <button className="copy-button" onClick={copyDisclaimerText}>Copy</button>
            {isCopyPopupVisible && <div className="copy-popup" id="copyPopup">Text Copied!</div>}
          </div>
          <a href={Link} target="_blank" onClick={mixpanelexpertisemsg}>
            <button className="messagebtn">Message {Name}</button>
          </a>
          <hr id="line" />
            <h4 id="sub-step"><span id="step">Step 2. </span>Message {Name}. Return here after you & {Name} have agreed to the terms for payment.</h4>
            <div className="paydetails-container">
              <div className="paydetails-column left-side">
                <p>Influence</p>
                <p>Fee</p>
                <p>Total</p>
              </div>
              <div className="paydetails-column right-side">
                <p>{price}</p>
                <Tippy className="tippy-tooltip" content="This fee is inactive. We'll begin to enforce a 5% marketplace fee at the commensement of our beta."><p id="fee-strike">${fee}</p></Tippy>
                <p>${total}</p>
              </div>
            </div>
          </div>
          <div className="pay-area">
          <span id="step">Select payment method</span>
          <div className="pay-container">
            <a href={ExpertiseLink} target="_blank" className="pay-box">
            <div className="pay-box-content"><img id="pay-icon" src={Stripe}></img><br/>Pay with card</div>
          </a>
          <a target="_blank" className="pay-box2">
            <div className="pay-box-content"><img id="pay-icon" src={Eth}></img><br/><ConnectButton />
            {isConnected && (
              <button onClick={() => {
                console.log('sendEther called');
                sendEther?.();
              }}>Pay</button>
            )}
            </div>
          </a>
          </div>
          </div>
        </div>
      </div>


    );
  };


  const ModalRectangle2 = ({ onClose, title, content, price }) => {
    const fee = parseFloat(price.replace('$', '')) * 0.05;
    const total = parseFloat(price.replace('$', ''));

    const mixpanelinfluencemsg = () => {
      mixpanel.track("Influence Message Click");
    };

    return (



      <div className="modal">
        <div className="modal-conten">
        <a onClick={onClose}><h4 id="back"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg> Go back</h4></a>
          <h3 id="modal-title">{title}</h3>
          <p id="modal-subtitle">{Name} (aka @{Handle}) will share a requested piece of content with their <span className={`follower-range ${followerRangeClassName}`}>{FollowerRange} followers</span></p>
          <div>
            <h4 id="sub-step"><span id="step">Step 1. </span>Message {Name}. Use the example message below or type your own.</h4>
            <div className="copy-section">
            <div className="copy-text">Hey {Name}, I saw your Influence deal on Upbird. Care to chat?</div>
            <button className="copy-button" onClick={copyDisclaimerText2}>Copy</button>
            {isCopyPopupVisible && <div className="copy-popup" id="copyPopup">Text Copied!</div>}
          </div>
          <a href={Link} target="_blank" onClick={mixpanelinfluencemsg}>
            <button className="messagebtn">Message {Name}</button>
          </a>
          <hr id="line" />
            <h4 id="sub-step"><span id="step">Step 2. </span>Message {Name}. Return here after you & {Name} have agreed to the terms for payment.</h4>
            <div className="paydetails-container">
              <div className="paydetails-column left-side">
                <p>Influence</p>
                <p>Fee</p>
                <p>Total</p>
              </div>
              <div className="paydetails-column right-side">
                <p>{price}</p>
                <Tippy className="tippy-tooltip" content="This fee is inactive. We'll begin to enforce a 5% marketplace fee at the commensement of our beta."><p id="fee-strike">${fee}</p></Tippy>
                <p>${total}</p>
              </div>
            </div>
          </div>
          <div className="pay-area">
          <span id="step">Select payment method</span>
          <div className="pay-container">
            <a href={InfluenceLink} target="_blank" className="pay-box">
            <div className="pay-box-content"><img id="pay-icon" src={Stripe}></img><br/>Pay with card</div>
          </a>
          <a target="_blank" className="pay-box2">
            <div className="pay-box-content"><img id="pay-icon" src={Eth}></img><br/><ConnectButton /></div>
          </a>
          </div>
          </div>
        </div>
      </div>

    );
  };
  

  const handleRectangleClick = () => {
    setIsModalOpen(true);
    mixpanel.track("Expertise box click");
  };

  const handleRectangle2Click = () => {
    setIsModal2Open(true);
    mixpanel.track("Influence box click");
  };

const followerRangeClassName = getFollowerRangeClassName(user.FollowerRange);

  return (
    <div className="user-page">
      <div className="user-info">
      <img src={Image} alt={Name} />
        <h2>{Name}</h2>
        <p id="user-page-handle">@{Handle}</p>
        <span className={`follower-range ${followerRangeClassName}`}>{FollowerRange} followers</span> <br/> <br/> <br/> <br/>
        <span className="profile-tags">
        {user.Tags &&
                  user.Tags.map((tag, tagIndex) => (
                    <span key={tagIndex} className={`tag ${tag.class}`}>
                      {tag.name}
                    </span>
                  ))}
        </span>
        </div>
      <div className="content-container-wo">

        <div className="offers-container">
          <h3 className="offers-text">Select an offer</h3>
          <div className="offer-rectangle" onClick={handleRectangleClick}>
            <h3 id="offer-title">Expertise</h3>
            <p id="offer-p">{Name} will work with you to create a requested piece of content for their <span className={`follower-range ${followerRangeClassName}`}>{FollowerRange} followers</span></p>
            <div className="price-container">
              <h1 className="price">{ExpertisePrice}</h1>
            </div>
          </div>
          <div className="offer-rectangle2" onClick={handleRectangle2Click}>
            <h3 id="offer-title">Influence</h3>
            <p id="offer-p">{Name} will share a requested piece of content to their <span className={`follower-range ${followerRangeClassName}`}>{FollowerRange} followers</span></p>
            <div className="price-container">
              <h1 className="price">{InfluencePrice}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="twitter-timeline">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName={Handle}
            className="TwitterTimelineEmbed"
            options={{
              height: '300',
              width: '100%',
            
              margin: 'auto',
            }}
            onLoad={() => console.log('Twitter timeline loaded')}
  onError={(error) => console.error('Twitter timeline error:', error)}
          />
        </div>

        <h3 id="howtitle">How it works</h3>
      <div className="howcard-container">
        <div className="howcard">
          <img id="howicon" src={searchIcon}></img>
          <h3 className="howcard-title">Find a creator</h3>
          <h2 className="howcard-sub">Browse Upbird to find a creator in your niche.</h2>
        </div>
        <div className="howcard">
        <img id="howicon" src={messageIcon}></img>
          <h3 className="howcard-title">Message & agree</h3>
          <h2 className="howcard-sub">Reach out regarding an offer on their profile.</h2>
        </div>
        <div className="howcard">
        <img id="howicon2" src={payIcon}></img>
          <h3 className="howcard-title">Pay & grow</h3>
          <h2 className="howcard-sub">Select a payment method & witness creator driven growth.</h2>
        </div>
      </div>

      {isModalOpen && <ModalRectangle onClose={closeModal} title="Expertise" price={ExpertisePrice} />}
      {isModal2Open && <ModalRectangle2 onClose={closeModal} title="Influence" price={InfluencePrice} />}
    </div>
    
  );
};

export default UserPage;