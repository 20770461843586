import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../styles/Navbar.css';

function Navbar() {
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState('Home');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isNewVisible, setIsNewVisible] = useState(Cookies.get('isNew') !== 'false');

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      setSelectedPage('Home');
    } else if (location.pathname === '/creators') {
      setSelectedPage('Creator');
    } else if (location.pathname === '/sell') {
      setSelectedPage('Sell');
    }
  }, [location]);

  const handlePageSelect = (page) => {
    setSelectedPage(page);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    if (windowWidth <= 600) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handleNewIndicatorClick = () => {
    setIsNewVisible(false);
    Cookies.set('isNew', false); // Remove the expires option
  };


  return (
    <div className="navbar">
      <div className="leftSide">
        <Link to="/">
          <h1 id="upbird">&#x1F426; Upbird</h1>
        </Link>
      </div>
      <div className="rightSide">
        {windowWidth > 600 && (
          <>
            <Link
              to="/"
              className={selectedPage === 'Home' ? 'selected' : ''}
              id="home"
              onClick={() => handlePageSelect('Home')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-house-door-fill"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
              </svg>
            </Link>
            <Link
              to="/sell"
              id="home"
              className={selectedPage === 'Sell' ? 'selected' : ''}
              onClick={() => {
                handlePageSelect('Sell');
                isNewVisible && handleNewIndicatorClick();
              }}
            >



               Sell on Upbird
            </Link>





          </>
        )}
        {windowWidth <= 600 && (
          <div className="hamburger" onClick={toggleDropdown}>
            <div className={`bar ${isDropdownOpen ? 'open' : ''}`} />
            <div className={`bar ${isDropdownOpen ? 'open' : ''}`} />
            <div className={`bar ${isDropdownOpen ? 'open' : ''}`} />
          </div>
        )}
        {isDropdownOpen && (
          <div className="dropdown">
            <Link
              to="/"
              className={selectedPage === 'Home' ? 'selected' : ''}
              id="home"
              onClick={() => handlePageSelect('Home')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-house-door-fill"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
              </svg>
            </Link>
            <Link
              to="/sell"
              id="home"
              className={selectedPage === 'Sell' ? 'selected' : ''}
              onClick={() => handlePageSelect('Sell')}
            >
              Sell on Upbird
            </Link>





          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;