import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Apply.css';
import mixpanel from 'mixpanel-browser';
import Select from 'react-select'
import Tippy from '@tippyjs/react';

mixpanel.init('43ddb0bbd6b4ee5d7a9b61987a52f9fd', { debug: true });

const ApplyPage = () => {
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
  
    const handleQuestion1Change = (selectedOption) => {
      setQuestion1(selectedOption);
    };
  
    const handleQuestion2Change = (event) => {
      setQuestion2(event.target.value);
    };
  
    const handleQuestion3Change = (event) => {
      setQuestion3(event.target.value);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // TODO: Handle form submission, e.g., connect to the backend, save data to a database, etc.
      // You can access the form values through the 'question1', 'question2', and 'question3' variables.
    };

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]

  
    return (
      <div>
        <h1>Apply Page</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="question1">What side(s) of twitter are you on?</label>
            <Select value={question1} onChange={handleQuestion1Change} options={options} isMulti />
          </div>
          <div>
            <label htmlFor="question2">Expertise price</label>
            <div id="calculator"><span id="new">NEW</span><p id="calculator-cta">Use the calculator</p></div>
            <p id="sub-question">What's your price for making a requested piece of content and sharing it with your followers?</p>
            <input
              type="number"
              id="question"
              name="ExpertisePrice"
              value={question2}
              onChange={handleQuestion2Change}
              required
            />
            <small>Enter a numerical value.</small>
          </div>
          <div>
            <label htmlFor="question3">Influence price</label>
            <p id="sub-question">What's your price for sharing a requested piece of content with your followers?</p>
            <input
              type="number"
              id="question"
              name="InfluencePrice"
              value={question3}
              onChange={handleQuestion3Change}
              required
            />
            <small>Enter a numerical value.</small>
          </div>
          <button type="submit">Next</button>
        </form>
      </div>
    );
  };
  
  export default ApplyPage;