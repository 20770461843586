import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { useQueryClient, QueryClient, QueryClientProvider } from 'react-query';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  goerli,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { InjectedConnector } from 'wagmi/connectors/injected'



const queryClient = new QueryClient();

const { chains, publicClient } = configureChains(
  [mainnet, goerli],
  [alchemyProvider({ apiKey: 'iQdlISVPHF7BtcCqS6rlWNmGWDGbOKj5' })],
)

const connector = new InjectedConnector({
  chains: [mainnet],
})


const { connectors } = getDefaultWallets({
  appName: 'Upbird',
  projectId: '7d651a1e0719b1005a97d07843c5b5c7',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})


ReactDOM.render(
  <QueryClientProvider client={queryClient} contextSharing={true}>
  <React.StrictMode>
  <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
    <App />
    </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
