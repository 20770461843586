import React from 'react';
import '../styles/Home.css';
import Offersdemo from '../img/upbirdhome.png';
import mixpanel from 'mixpanel-browser';

const HomePage = () => {

  const mixpanelbrowsebtn = () => {
    mixpanel.track("Home browse click");
  };

  return (
    <div className="top-home">
      <h1 id="heading-home">Sell <span id="blue-heading">Twitter services</span> directly to your audience</h1>
      <h2 id="subheading-home">A creator marketplace that lets anyone leverage their Twitter know-how to earn real $.</h2>
      <div className="btns-home">
      <a href="https://tally.so/r/3XrVvd" target="_blank"><button id="btn-1">Sell on Upbird</button></a>
      <a href=""><button id="btn-2" onClick={mixpanelbrowsebtn}>See creators</button></a>
      </div>
      <div className="bottom-home">
        <h2 id="examples">Twitter services? See examples..</h2>
        <div>
          <div className="offer-rectangle3">
            <h3 id="offer-title3">Thread</h3>
            <p id="offer-p3">Threadguy will write a custom Twitter thread for you.</p>
            <div className="price-container3">
              <p id="offer-quant">1 time</p>
              <h1 className="price3">$45</h1>
            </div>
          </div>
          <div className="offer-rectangle3">
            <h3 id="offer-title3">Ghost writer</h3>
            <p id="offer-p3">Clint will tweet for your brand for a specified amount of time.</p>
            <div className="price-container3">
              <h1 className="price3">Custom</h1>
            </div>
          </div>
          <div className="offer-rectangle-special">
            <h3 id="offer-title-special">&#128142; Your idea</h3>
            <p id="offer-p-special">Insert your Twitter service description here.</p>
            <div className="price-container-special">
              <a href="https://tally.so/r/3XrVvd"><button className="special-cta">Sell on Upbird</button></a>
            </div>
          </div>
          <div className="offer-rectangle3">
            <h3 id="offer-title3">Tweet talk</h3>
            <p id="offer-p3">G will consult with you about your Twitter brand strategy.</p>
            <div className="price-container3">
              <p id="offer-quant">1 time</p>
              <h1 className="price3">$15</h1>
            </div>
          </div>
          <div className="offer-rectangle3">
            <h3 id="offer-title3">Spaces host</h3>
            <p id="offer-p3">IV will host a 1hr Twitter space for your brand.</p>
            <div className="price-container3">
              <p id="offer-quant">1 time</p>
              <h1 className="price3">$40</h1>
            </div>
          </div>
        </div>
        </div>
        </div>
      
    
  );
};

export default HomePage;