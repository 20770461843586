import React, { useState, useEffect } from 'react';
import '../styles/Calculator.css';
import Tippy from '@tippyjs/react';

const CalculatorPage = () => {
  const [followerCount, setFollowerCount] = useState('');
  const [avgLikes, setAvgLikes] = useState('');
  const [result, setResult] = useState(null);

  const calculatePrices = () => {
    const expertiseFairPriceCap = calculateExpertisePrice();
    const influenceFairPriceCap = calculateInfluencePrice();
    setResult({ expertiseFairPriceCap, influenceFairPriceCap });
  };

  const calculateExpertisePrice = () => {
    let avgLikesMultiplier = 0;
    let followerMultiplier = 0;
  
    if (followerCount < 2000) {
      avgLikesMultiplier = 0.7;
      followerMultiplier = 0.012;
      if (avgLikes < 1) {
        avgLikesMultiplier *= 0.01; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 4000) {
      avgLikesMultiplier = 0.32;
      followerMultiplier = 0.009;
      if (avgLikes < 1) {
        avgLikesMultiplier *= 0.01; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 6000) {
      avgLikesMultiplier = 0.34;
      followerMultiplier = 0.008;
      if (avgLikes < 2) {
        avgLikesMultiplier *= 0.3;
        followerMultiplier *= 0.008; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 8000) {
      avgLikesMultiplier = 0.35;
      followerMultiplier = 0.0065;
      if (avgLikes < 4) {
        avgLikesMultiplier *= 0.3;
        followerMultiplier *= 0.008;// Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 10000) {
      avgLikesMultiplier = 0.35;
      followerMultiplier = 0.008;
      if (avgLikes < 5) {
        avgLikesMultiplier *= 0.3;
        followerMultiplier *= 0.008; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 12000) {
      avgLikesMultiplier = 0.375;
      followerMultiplier = 0.009;
      if (avgLikes < 6) {
        avgLikesMultiplier *= 0.3;
        followerMultiplier *= 0.008; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 14000) {
      avgLikesMultiplier = 0.375;
      followerMultiplier = 0.0095;
      if (avgLikes < 7) {
        avgLikesMultiplier *= 0.3;
        followerMultiplier *= 0.008; // Reduce the follower multiplier for low followers and low likes
      } //marrrrrkkrkrkr
    } else if (followerCount < 16000) {
      avgLikesMultiplier = 0.34;
      followerMultiplier = 0.008;
      if (avgLikes < 8) {
        avgLikesMultiplier *= 0.3;
        followerMultiplier *= 0.008; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 18000) {
      avgLikesMultiplier = 0.36;
      followerMultiplier = 0.0085;
      if (avgLikes < 9) {
        avgLikesMultiplier *= 0.3;
        followerMultiplier *= 0.008; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 20000) {
      avgLikesMultiplier = 0.38;
      followerMultiplier = 0.009;
      if (avgLikes < 10) {
        avgLikesMultiplier *= 0.3;
        followerMultiplier *= 0.008; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 25000) {
      avgLikesMultiplier = 0.40;
      followerMultiplier = 0.0095;
      if (avgLikes < 10) {
        avgLikesMultiplier *= 0.3;
        followerMultiplier *= 0.008; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 30000) {
      avgLikesMultiplier = 0.012;
      followerMultiplier = 0.065;
      if (avgLikes < 2) {
        avgLikesMultiplier *= 0.01; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 35000) {
      avgLikesMultiplier = 0.013;
      followerMultiplier = 0.07;
      if (avgLikes < 2) {
        avgLikesMultiplier *= 0.01; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 40000) {
      avgLikesMultiplier = 0.014;
      followerMultiplier = 0.075;
      if (avgLikes < 2) {
        avgLikesMultiplier *= 0.01; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 45000) {
      avgLikesMultiplier = 0.015;
      followerMultiplier = 0.08;
      if (avgLikes < 2) {
        avgLikesMultiplier *= 0.01; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 50000) {
      avgLikesMultiplier = 0.016;
      followerMultiplier = 0.085;
      if (avgLikes < 2) {
        avgLikesMultiplier *= 0.01; // Reduce the follower multiplier for low followers and low likes
      }
    } else {
      avgLikesMultiplier = 0.017;
      followerMultiplier = 0.09;
      if (avgLikes < 2) {
        avgLikesMultiplier *= 0.01; // Reduce the follower multiplier for low followers and low likes
      }
    }
  
    const expertisePrice = Math.round(followerCount * (avgLikes / 1800) * avgLikesMultiplier + followerCount * followerMultiplier);
    return expertisePrice;
    
  };
  
  const calculateInfluencePrice = () => {
    let avgLikesMultiplier = 0;
    let followerMultiplier = 0;
  
    // Adjust the multipliers as per your desired impact
  
    if (followerCount < 2000) {
      avgLikesMultiplier = 0.3;
      followerMultiplier = 0.006;

    } else if (followerCount < 4000) {
      avgLikesMultiplier = 0.14;
      followerMultiplier = 0.005;
      if (avgLikes < 1) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 6000) {
      avgLikesMultiplier = 0.15;
      followerMultiplier = 0.004  ;
      if (avgLikes < 1) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 8000) {
      avgLikesMultiplier = 0.155;
      followerMultiplier = 0.0035;
      if (avgLikes < 4) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 10000) {
      avgLikesMultiplier = 0.158;
      followerMultiplier = 0.0032;
      if (avgLikes < 5) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 12000) {
      avgLikesMultiplier = 0.16;
      followerMultiplier = 0.0031;
      if (avgLikes < 6) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 14000) {
      avgLikesMultiplier = 0.164;
      followerMultiplier = 0.003;
      if (avgLikes < 7) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      } //marrrrrkkrkrkr
    } else if (followerCount < 16000) {
      avgLikesMultiplier = 0.168;
      followerMultiplier = 0.0028;
      if (avgLikes < 8) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 18000) {
      avgLikesMultiplier = 0.176;
      followerMultiplier = 0.0029;
      if (avgLikes < 9) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 20000) {
      avgLikesMultiplier = 0.184;
      followerMultiplier = 0.003;
      if (avgLikes < 10) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 25000) {
      avgLikesMultiplier = 0.192;
      followerMultiplier = 0.0031;
      if (avgLikes < 12) {
        avgLikesMultiplier *= .2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 30000) {
      avgLikesMultiplier = 0.2;
      followerMultiplier = 0.0032;
      if (avgLikes < 12) {
        avgLikesMultiplier *= 0.2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 35000) {
      avgLikesMultiplier = 0.208;
      followerMultiplier = 0.0033;
      if (avgLikes < 12) {
        avgLikesMultiplier *= 0.2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 40000) {
      avgLikesMultiplier = 0.216;
      followerMultiplier = 0.0034;
      if (avgLikes < 12) {
        avgLikesMultiplier *= 0.2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 45000) {
      avgLikesMultiplier = 0.232;
      followerMultiplier = 0.0035;
      if (avgLikes < 12) {
        avgLikesMultiplier *= 0.2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else if (followerCount < 50000) {
      avgLikesMultiplier = 0.264;
      followerMultiplier = 0.0036;
      if (avgLikes < 15) {
        avgLikesMultiplier *= 0.2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    } else {
      avgLikesMultiplier = 0.27;
      followerMultiplier = 0.004;
      if (avgLikes < 2) {
        avgLikesMultiplier *= 0.2;
        followerMultiplier *= 0.003; // Reduce the follower multiplier for low followers and low likes
      }
    }
  
    const influencePrice = Math.round(followerCount * (avgLikes / 1000) * avgLikesMultiplier + followerCount * followerMultiplier);
    return influencePrice;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculatePrices();
  };

  useEffect(() => {
    if (result) {
      const rangePercentage = (result.expertiseFairPriceCap / result.influenceFairPriceCap) * 100;
      const end = Math.round(rangePercentage);
      const indicatorEnd = end > 0 ? end : 1;
      setResult((prevState) => ({ ...prevState, indicatorEnd }));
    }
  }, [result]);

  return (
    <div className="calcpage">
      <div className="calctop">
        <h1 id="calc-title">How to price packages on Upbird</h1>
        <p id="calc-explainer">We created <span id="upbirdstandard">Fair Value Maximum</span>, a standard to accurately price your reach on Twitter. <a target="_blank" href="https://github.com/hashmonke/upbird-fair-value/tree/main">It's open source</a>.</p>
        <p id="fairvalue">We now highlight those who price their packages at, or below their generated price. <a target="_blank" href="https://twitter.com">Learn more</a></p>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="calc" htmlFor="followerCount">Follower count</label> <br />
          <input
            type="number"
            id="followerCount"
            value={followerCount}
            placeholder="0"
            onChange={(e) => setFollowerCount(Number(e.target.value))}
            required
          />
        </div>
        <div>
          <label className="calc" htmlFor="avgLikes">Average likes per tweet</label> <br />
          <input
            type="number"
            id="followerCount"
            value={avgLikes}
            placeholder="0"
            onChange={(e) => setAvgLikes(Number(e.target.value))}
            required
          />
        </div>
        <button className="calc-btn" type="submit">
          Calculate
        </button>
      </form>
      {result && (
        <div className="price-content">
          <div className="price-section">
            <span id="price-title">Expertise FVM<Tippy Class="tippy-tooltip" content="Your accounts Fair Value Maximum (FVM) make & share a requested piece of content to your audience."><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="infoicon" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg></Tippy></span>
            <h1 id="fair-price">${result.expertiseFairPriceCap}</h1>
          </div>
          <div className="price-section">
            <span id="price-title">Influence FVM<Tippy Class="tippy-tooltip" content="Your accounts Fair Value Maxmium (FVM) to share a requested piece of content to your audience"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="infoicon" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg></Tippy></span>
            <h1 id="fair-price">${result.influenceFairPriceCap}</h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalculatorPage;