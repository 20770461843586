import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Sell.css';
import '../App.css';

const SellPage = () => {
  return (
    <div className="sell-page">
      <div className="sell-top">
        <h2 className="sub-header">How to sell on...</h2>
      </div>
      <div className="sell-header">
        <h1>
          The marketplace that lets <span className="colored-text">anyone monetize</span> their reach on Twitter.
        </h1>
      </div>
      <a href="https://tally.so/r/w8zPlO" target="_blank">
        <button className="apply-button">Apply for early creator access</button>
      </a>
      <div className="sell-top">
        <h3 className="card-heading">Already a seller?</h3>
      </div>
      <h2 className="step4-title">Here's all you need.</h2>
      <div className="linkcard-container">
      <a href="https://connect.stripe.com/express_login" target="_blank">
        <div className="linkcard">
          <h3 className="linkcard-title">See my sales dashboard</h3>
          <h2 className="linkcard-sub">Click here</h2>
        </div>
        </a>
        <a href="https://discord.gg/U2CuPvG2nq" target="_blank">
        <div className="linkcard">
          <h3 className="linkcard-title">Request a feature</h3>
          <h2 className="linkcard-sub">Click here</h2>
        </div>
        </a>
        <a href="https://discord.gg/U2CuPvG2nq" target="_blank">
        <div className="linkcard">
          <h3 className="linkcard-title">Change my profile info</h3>
          <h2 className="linkcard-sub">Click here</h2>
        </div>
        </a>
      </div>
      <div className="sell-top">
        <h3 className="card-heading">Start here</h3>
      </div>
      <h2 className="step4-title">Twitter users have a new way to get paid.</h2>
      <h3 id="announce"><span id="new-tag">NEW</span> We made a tool to help price your packages on Upbird. <a href="./price">Try it out</a></h3>
      <div className="card-container">
        <div className="card">
          <h3 className="card-heading">Step 1</h3>
          <h2 className="card-title">Apply for early creator access</h2>
          <a href="https://tally.so/r/w8zPlO" target="_blank">
            <button className="card-button">Apply here</button>
          </a>
        </div>
        <div className="card">
          <h3 className="card-heading">Step 2</h3>
          <h2 className="card-title">Setup payout method</h2>
          <button className="card-message">You'll receive a message</button>
        </div>
        <div className="card">
          <h3 className="card-heading">Step 3</h3>
          <h2 className="card-title">Expertise & Influence links</h2>
          <button className="card-message">You'll receive a message</button>
        </div>
      </div>
      <div className="sell-top">
        <h3 className="card-heading">Step 4</h3>
      </div>
      <h2 className="step4-title">Sit back & monetize your reach!</h2>
      <h2 className="step4-sub">
        Upbird v1 serves offers to your Twitter DMs. Keep an eye out, find the offer that's right for you, receive
        payment, & use your reach.
      </h2>
      <button className="card-message">You'll receive a message</button>
    </div>
  );
};

export default SellPage;